import React, { useEffect, useRef } from "react";
import BackgroundImage from "gatsby-background-image";
import Button from "../Button/button";
import BannerButton from "../BannerButton/bannerButton";
import { Link } from "react-scroll";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { BannerStyles } from "../../styles/bannerStyles";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import interpolate from "interpolate-range";

import TwinzuLogo from "../../images/twinzu-white.svg";
import AppLogo from "../../images/App.svg";
import DataLogo from "../../images/Data.svg";
import PrototypLogo from "../../images/Frontend.svg";
import BiznisLogo from "../../images/Business.svg";

const HeroAlt = (props) => {
  const bgImage = convertToBgImage(
    getImage(props.illustr.localFile.childImageSharp)
  );

  const paralaxRef = useRef();
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      // controls.start("hidden")
    }
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [controls, inView]);

  const scrollHandler = () => {
    const offsetMin = paralaxRef.current.selfRef.offsetTop;
    const offsetMax =
      paralaxRef.current.selfRef.offsetTop +
      paralaxRef.current.selfRef.offsetHeight;

    const config = {
      inputRange: [
        offsetMin - paralaxRef.current.selfRef.offsetHeight * 0.5,
        offsetMax + paralaxRef.current.selfRef.offsetHeight * 0.5,
      ],
      outputRange: [0, paralaxRef.current.selfRef.offsetHeight * 2],
      clamp: true,
    };
    const inter = interpolate(config);

    if (
      window.pageYOffset + window.innerHeight >= offsetMin &&
      window.pageYOffset < offsetMax
    )
      paralaxRef.current.selfRef.style.perspectiveOrigin =
        "center " +
        (inter(window.pageYOffset) - paralaxRef.current.selfRef.offsetHeight) +
        "px";
  };

  return (
    <BannerStyles>
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="hero-image paralax"
        ref={paralaxRef}
        id="layers"
      >
        <div className="hero-content alt">
          <div className="textBlock">
            {props.leadLine && (
              <motion.h3
                initial="hidden"
                ref={ref}
                animate={controls}
                variants={variants}
                transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
              >
                {props.leadLine}
              </motion.h3>
            )}
            <motion.h1
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8 }}
            >
              {props.title}
            </motion.h1>
            <motion.p
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
            >
              {props.description}
            </motion.p>

            <a
              href={
                props.download
                  ? props.download
                  : props.link
                    ? props.link
                    : props.category
                      ? "/" + props.category + "/" + props.slug
                      : "/" + props.slug
              }
              className="cta"
            >
              <Button
                label={props.buttonText}
                cta={props.buttonText}
                colors={props.colors}
              />
            </a>
          </div>
          <div className="bannerLinks">
            <BannerButton
              Icon={PrototypLogo}
              href={
                props.locale === "en"
                  ? "/en/pages/multi-layered-architecture"
                  : "/pages/viacvrstvova-architektura"
              }
              className="prototyp alt"
            />
            <BannerButton
              Icon={TwinzuLogo}
              LinkTo="block-twinzu"
              className="twinzu alt"
            />
            <BannerButton
              Icon={DataLogo}
              href={
                props.locale === "en"
                  ? "/en/pages/multi-layered-architecture"
                  : "/pages/viacvrstvova-architektura"
              }
              className="data alt"
            />
            {/*
            <BannerButton
              Icon={AppLogo}
              LinkTo="block-projekty"
              className="app alt"
            />
            */}
            <BannerButton
              Icon={BiznisLogo}
              href={
                props.locale === "en"
                  ? "/en/pages/multi-layered-architecture"
                  : "/pages/viacvrstvova-architektura"
              }
              className="biznis alt"
            />
          </div>
        </div>
      </BackgroundImage>
    </BannerStyles>
  );
};

export default HeroAlt;
