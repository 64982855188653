import React, { useEffect, useRef } from "react";
import BackgroundImage from "gatsby-background-image";
import BannerButton from "../BannerButton/bannerButton";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { BannerStyles } from "../../styles/bannerStyles";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import interpolate from "interpolate-range";
import { Link } from "react-scroll";

import sipka from "../../images/sipka.svg";
import sipkaBlack from "../../images/sipka-black.svg";
import AppLogo from "../../images/App.svg";
import DataLogo from "../../images/Data.svg";
import PrototypLogo from "../../images/Frontend.svg";
import BiznisLogo from "../../images/Business.svg";

const Hero = (props) => {
  const bgImage = convertToBgImage(
    getImage(props.illustr.localFile.childImageSharp)
  );
  const paralaxRef = useRef();
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };
  const variants2 = {
    visible: { opacity: 1, y: 0, scale: 1 },
    hidden: { opacity: 0, y: 20, scale: 2 },
  };
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      //controls.start("hidden");
    }
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [controls, inView]);

  const scrollHandler = () => {
    const offsetMin = paralaxRef.current.selfRef.offsetTop;
    const offsetMax =
      paralaxRef.current.selfRef.offsetTop +
      paralaxRef.current.selfRef.offsetHeight;

    const config = {
      inputRange: [
        offsetMin - paralaxRef.current.selfRef.offsetHeight * 0.5,
        offsetMax + paralaxRef.current.selfRef.offsetHeight * 0.5,
      ],
      outputRange: [0, paralaxRef.current.selfRef.offsetHeight * 2],
      clamp: true,
    };
    const inter = interpolate(config);

    if (
      window.pageYOffset + window.innerHeight >= offsetMin &&
      window.pageYOffset < offsetMax
    )
      paralaxRef.current.selfRef.style.perspectiveOrigin =
        "center " +
        (inter(window.pageYOffset) - paralaxRef.current.selfRef.offsetHeight) +
        "px";
  };

  return (
    <BannerStyles>
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="hero-image paralax"
        ref={paralaxRef}
      >
        <div className="hero-content">
          <motion.h1
            initial="visible"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 0 }}
          >
            {props.title}
          </motion.h1>
          <motion.p
            initial="visible"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
          >
            {props.description}
          </motion.p>
          <div className="bannerLinks">
            <motion.div
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants2}
              transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
            >
              <BannerButton
                Icon={PrototypLogo}
                Title={
                  props.locale === "sk" ? (
                    <span>
                      Prezentačná <br />
                      vrstva
                    </span>
                  ) : (
                    <span>
                      Presentation <br />
                      layer
                    </span>
                  )
                }
                href={
                  props.locale === "en"
                    ? "/en/pages/multi-layered-architecture"
                    : "/pages/viacvrstvova-architektura"
                }
              />
            </motion.div>
            {/*
              <motion.div
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants2}
              transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
            >
              <BannerButton
                Icon={AppLogo}
                Title={
                  props.locale === "sk" ? (
                    <span>
                      Aplikačná <br />
                      vrstva
                    </span>
                  ) : (
                    <span>
                      Application <br />
                      layer
                    </span>
                  )
                }
                LinkTo="layers"
              />
            </motion.div>
              */}

            <motion.div
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants2}
              transition={{ ease: "easeOut", duration: 0.8, delay: 1.4 }}
            >
              <BannerButton
                Icon={BiznisLogo}
                Title={
                  props.locale === "sk" ? (
                    <span>
                      Biznis <br />
                      vrstva
                    </span>
                  ) : (
                    <span>
                      Business <br />
                      layer
                    </span>
                  )
                }
                href={
                  props.locale === "en"
                    ? "/en/pages/multi-layered-architecture"
                    : "/pages/viacvrstvova-architektura"
                }
              />
            </motion.div>
            <motion.div
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants2}
              transition={{ ease: "easeOut", duration: 0.8, delay: 1.6 }}
            >
              <BannerButton
                Icon={DataLogo}
                Title={
                  props.locale === "sk" ? (
                    <span>
                      Dátová <br />
                      vrstva
                    </span>
                  ) : (
                    <span>
                      Data <br />
                      layer
                    </span>
                  )
                }
                href={
                  props.locale === "en"
                    ? "/en/pages/multi-layered-architecture"
                    : "/pages/viacvrstvova-architektura"
                }
              />
            </motion.div>
          </div>
          <Link
            to="block-twinzu"
            smooth={true}
            duration={500}
            className="link"
            href={"#twinzu"}
          >
            <img className="sipka" src={sipka} />
          </Link>
        </div>
      </BackgroundImage>
    </BannerStyles>
  );
};

export default Hero;
