import styled from "styled-components";

export const BannerStyles = styled.section`
  width: 100%;
  .gatsby-image-wrapper {
    height: 100vh;
    color: #fff;
    position: relative;
  }
  .hero-image {
    height: 100vh;
  }
  .hero-content {
    color: white;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 480px;
    padding: 0 20px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    @media (min-width: 768px) {
      max-width: 880px;
    }
    @media (max-width: 991px) {
      padding: 0px;
      max-width: 100%;
      padding-top: 60px;
      .cta {
        text-align: center;
      }
    }

    .bannerLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      align-content: flex-start;

      @media (max-width: 991px) {
        margin: 0 -30px;
      }

      @media (max-width: 400px) {
        display: none;
      }
    }
    h1 {
      font-size: 1.75rem;
      line-height: 1.2;
      margin-bottom: 0;
      margin-top: 1em;
      font-weight: bold;

    }
    h3 {
      color: white;
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    p {
      margin-top: 0;
      font-weight: bold;
      margin-bottom: 4rem;
      line-height: 1.2;
      font-size: 1.75rem;

      @media (min-width: 768px) {
        font-size: 3rem;
      }

      @media (min-width: 1200px) {
        font-size: 4rem;
      }
      @media (min-width: 768px and max-height: 720px) {
        font-size: 2rem;
      }
    }

    &.alt {
      max-width: 1160px;
      text-align: left;
      flex-direction: row;
      align-items: center;

      @media (max-width: 991px) {
        flex-direction: column;
      }
      .bannerButton {
        position: absolute;
        transition: all 0.3s;
      }
      .textBlock {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        @media (min-width: 992px) {
          max-width: 400px;
        }
      }
      p {
        font-size: 1.25rem;
        line-height: 1.8;
        max-width: 400px;
        font-weight: normal;
        margin-bottom: 2em;
        @media (max-width: 991px) {
          max-width: 750%;
          text-align: center;
          margin: 0 auto 1rem auto;
        }
      }
      h1 {
        font-size: 2.6rem;
        margin-bottom: 1rem;
        margin-top: 0;
        max-width: 400px;
        flex: 0;

        @media (max-width: 991px) {
          max-width: 75%;
          text-align: center;
          margin: 0 auto 1rem auto;
          font-size: 2rem;
        }
      }
      .data {
        top: 44%;
        right: 66%;
        @media (max-width: 991px) {
          top: -10%;
          right: 71%;
        }
      }
      .app {
        top: 20%;
        right: 13%;
        @media (max-width: 991px) {
          top: -30%;
          right: 0%;
        }
      }
      .prototyp {
        top: 66%;
        right: 53%;
        @media (max-width: 991px) {
          top: 24%;
          right: 51%;
        }
      }
      .biznis {
        top: 50%;
        right: 16%;
        @media (max-width: 991px) {
          top: 20%;
          right: 10%;
        }
      }
      .twinzu {
        top: 20%;
        right: 52%;
        @media (max-width: 991px) {
          top: -20%;
          right: 30%;
        }
      }
      .bannerLinks {
        flex: 1;
        align-self: stretch;
        position: relative;
        @media (max-width: 991px) {
          display: none;
        }
      }
      button {
        align-self: start;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    button,
    .anchor {
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      max-width: 880px;

      h1 {
        font-size: 3rem;
      }
    }
      @media (min-width: 768px and max-height: 720px) {
      max-width: 100%;
        h1{
          font-size: 2rem;
          margin-top: 3em;
        }
      }

    @media (min-width: 1200px) {
      h1 {
        font-size: 4rem;
      }
    }
  }
`;
