import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Halo from "../../images/halo.svg";

const BannerButton = ({ Icon, LinkTo, Title, className, href }) => {
  return (
    <ButtonStyles className={"bannerButton " + className}>
      {LinkTo && (
        <Link
          to={LinkTo}
          smooth={true}
          duration={500}
          className="link"
          href={"#" + LinkTo}
        >
          <div className="halo">
            <img src={Halo} alt="" />
          </div>
          <span className="icnWrap">
            <img src={Icon} alt="" className="icon" />
          </span>
          <span className="title">{Title && Title}</span>
        </Link>
      )}
      {href && (
        <a className="link" href={href}>
          <div className="halo">
            <img src={Halo} alt="" />
          </div>
          <span className="icnWrap">
            <img src={Icon} alt="" className="icon" />
          </span>
          <span className="title">{Title && Title}</span>
        </a>
      )}
    </ButtonStyles>
  );
};

export const ButtonStyles = styled.div`
  @keyframes rotateme {
    to {
      transform: rotate(360deg);
    }
  }
  width: 150px;
  margin: 1em;
  position: relative;
  backdrop-filter: blur(10px);
  border-radius: 50%;
  text-align: center;
  transform: scale(1);
  transition: transform 0.3s;
  opacity: 0.8;
  .link {
    display: block;
    text-decoration: none;

    .halo {
      position: absolute;
      will-change: transform;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.6);
      transition: all 0.3s;
      opacity: 0;
      pointer-events: none;
    }
    .title {
      border: 1px solid #ffffff4a;
      border-radius: 50%;
      transition: all 0.3s;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em;
      line-height: 1.3em;
      color: #ffffffbd;
      font-weight: bold;
      font-size: 17px;
      background: linear-gradient(
        180deg,
        rgba(24, 32, 79, 0) 0%,
        rgba(24, 32, 79, 0.147) 100%
      );
    }
    span {
      display: block;
    }
    .icnWrap {
      background-color: #3a3a3abd;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s;
      will-change: transform;
      z-index: 1;
      .icon {
        height: 20px;
      }
    }
  }

  &:hover {
    transform: scale(1.2);
    z-index: 1;
    opacity: 1;
    .title {
      color: #ffffff;
      box-shadow: 3px 73px 63px rgb(94 110 209 / 80%);
    }
    .link {
      .icnWrap {
        background-color: #2243ebbd;
      }
      .halo {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        img {
          animation: rotateme 2s alternate infinite;
        }
      }
    }
  }
  &.alt {
    .icnWrap {
      background-color: transparent !important;
      transform: translate(-50%, 50%);
      width: 80px;
      height: 74px;
      overflow: visible;
      &:before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        background-color: white;
        box-shadow: 0 0 30px 10px white;
        position: absolute;
        right: -70px;
        top: 50%;
        transform: translateY(-50%);
        transition: right 0.3s;
        border-radius: 50%;
      }
      .icon {
        height: 50px;
      }
    }
    &:hover .icnWrap:before {
      right: -40px;
    }
  }
`;

export default BannerButton;
